.ais-Pagination-link--selected {
  background-color: #FFFFFF !important;
  border-color: #6d6d6b !important;
  color: #E14C2E !important;;
}

.ais-Pagination-link {
  color: #6d6d6b;
  :hover {
    color: #6d6d6b;
  }
}

.ais-Pagination-link:hover {
  color: #6d6d6b;
}
