@media (max-width: 600px) {
  .ais-SearchBox {
    width: 80vw;
  }
}

@media  (min-width: 600px) {
  .ais-SearchBox {
    width: 40vw;
  }
}
